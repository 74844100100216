<template>
  <div>
    <img class="banner" :src="banner" />

    <div class="mydata">
      <ul>
        <template v-for="item in list" :key="item.text">
          <li @click="navigateTo(item.image)">
            <img class="img" src="@/assets/images/doubt.png" />
            <span>{{ item.text }}</span>
          </li>

          <template v-for="child in item.children" :key="child.text">
            <li @click="navigateTo(child.image)">
              <img class="img" src="" />
              <span>{{ child.text }}</span>
            </li>
          </template>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
const studentList = [
  {
    text: '如何修改个人信息及上传简历',
    image:
      'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-33931606877095552.png',
  },
  {
    text: '核心功能操作:',
    children: [
      {
        text: '如何选择心仪职位，投递简历',
        image:
          'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-84191606877679912.png',
      },
      {
        text: '如何查看收到的offer',
        image:
          'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-13111606877285701.png',
      },
    ],
  },
  {
    text: '本校宣讲室的使用流程',
    image:
      'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-53981606877759136.png',
  },
  {
    text: '本校双选会的使用流程',
    image:
      'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-49501606877838646.png',
  },
  {
    text: '面试室的操作说明',
    image:
      'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-97061606877158377.png',
  },
  {
    text: '职前课堂的操作说明',
    image:
      'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-22941606877876907.png',
  },
  {
    text: '如何进行面试及入职评价',
    image:
      'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-58601606877218151.png',
  },
];

const companyList = [
  {
    text: '基本信息操作：',
    children: [
      {
        text: '如何修改企业资料',
        image:
          'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-84271606877059199.png',
      },
      {
        text: '如何发布及管理已发职位',
        image:
          'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-33231606877428083.png',
      },
      {
        text: '如何查看学生简历及进行邀请面试、发放offer',
        image:
          'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-99261606877188198.png',
      },
      {
        text: '如何查看发放offer的情况',
        image:
          'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-33221606877248491.png',
      },
    ],
  },
  {
    text: '购买线上宣讲会',
    image:
      'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-78331606876907495.png',
  },
  {
    text: '购买线上双选会',
    image:
      'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-98841606877025401.png',
  },
  {
    text: '宣讲室的使用准备流程：',
    children: [
      {
        text: '购买指定的高校线上宣讲会，需要进行以下操作',
        image:
          'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-58341606877712720.jpg',
      },
      {
        text: '如何录制视频',
        image:
          'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-56431606877390618.jpg',
      },
    ],
  },
  {
    text: '宣讲室的使用流程',
    image:
      'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-5391606877356614.png',
  },
  {
    text: '如何进行面试及入职评价',
    image:
      'http://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/OperationManual/OperationManual-44261606877324456.png',
  },
];

export default {
  name: 'Guide',

  data() {
    return {
      banner: '',
      list: [],
    };
  },

  watch: {
    '$route.name': {
      handler(val) {
        if (val === 'StudentGuide') {
          this.banner = require('@/assets/images/guide_student.png');
          this.list = studentList;
        } else if (val === 'CompanyGuide') {
          this.banner = require('@/assets/images/guide_company.png');
          this.list = companyList;
        }
      },
      immediate: true,
    },
  },

  methods: {
    navigateTo(img) {
      if (!img) return;

      this.$router.push({
        path: `/guide/show`,
        query: {
          img: encodeURIComponent(img),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: auto;
}
.img {
  width: 20px;
  height: auto;
}

.mydata {
  width: 100%;
  overflow: hidden;
}

li {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(247, 247, 247, 1);
  padding: 0.96rem 1.28rem;
}

span {
  font-size: 14px;
  font-family: PingFangSC-Light;
  font-weight: 300;
  color: rgba(52, 52, 52, 1);
  line-height: 0.96rem;
  margin-left: 0.32rem;
}
</style>
